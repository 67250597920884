import { getImage } from 'gatsby-plugin-image'

import CompanyWhyHireBlock from '~/components/blocks/CompanyWhyHireBlock'
import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import Testimonial from '~/components/blocks/TestimonialPost'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import SuccessStoriesSliderSection from '~/components/sections/SuccessStoriesSliderSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/mvp/Anchors'
import MvpApproach from '~/mock/services/mvp/MvpApproach'
import MvpBenefits from '~/mock/services/mvp/MvpBenefits'
import MvpBusinesses from '~/mock/services/mvp/MvpBusinesses'
import MvpDevExpertise from '~/mock/services/mvp/MvpDevExpertise'
import MvpExpertise from '~/mock/services/mvp/MvpExpertise'
import MvpFAQ from '~/mock/services/mvp/MvpFAQ'
import MvpProcess from '~/mock/services/mvp/MvpProcess'
import MvpServices from '~/mock/services/mvp/MvpServices'
import MvpWhyChoose from '~/mock/services/mvp/MvpWhyChoose'
import { fileToImageLikeData } from '~/utils'
import CompanyProjectsLogoSection from '~/views/Homepage/components/CompanyProjectsLogoSection'
import AwardsServicesSection from '~/views/Services/components/AwardsServicesSection'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesSectionBeforeTestimonials from '~/views/Services/components/ServicesSectionBeforeTestimonials'
import ServicesTechStackSection from '~/views/Services/components/ServicesTechStackSection'

import * as containerStyles from './MvpDevelopment.module.scss'
import useMvpDevelopmentStaticQuery from './useMvpDevelopmentStaticQuery'

const MvpDevelopment = () => {
  const query = useMvpDevelopmentStaticQuery()
  const clientImpact = getImage(fileToImageLikeData(query.clientImpact))
  const launchYourProduct = getImage(
    fileToImageLikeData(query.launchYourProduct),
  )
  const quoteCodicaCEOPhoto = getImage(
    fileToImageLikeData(query.quoteCodicaCEOPhoto),
  )

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="MVP Development"
        title="Services"
        description="With our MVP development services, we help entrepreneurs quickly validate business ideas and save time and budget."
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="MVP Development Services"
        breadcrumbCurrentPath="/services/mvp-development/"
      />

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.servicesCardBorderIconMvpSection}
      >
        <HeadSection
          position="center"
          title="MVP development services bespoke at Codica"
          description="Codica is a one-stop-shop for minimum viable product development. Whether you need a web solution or a cross-platform one, we got you covered. As an MVP development company, we have a team of 60+ seasoned experts to help you with all the stages of MVP creation."
          descriptionLarge
        />
        <Service3DIconCard dataContent={MvpServices} />
      </section>

      <section
        id="industries"
        className={containerStyles.domainExpertiseMvpSection}
      >
        <HeadSection
          position="center"
          title="MVPs for different industries"
          description="Our team has built many successful MVP products for various businesses from all over the world. Thus, we have accumulated multi-domain expertise that we apply to every new project."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={MvpExpertise} />
      </section>

      <CallToAction
        title="Do you need to validate a business idea quickly?"
        subtitle="We will help to bring it to life."
        classNameForGA="servicesCTA_1"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section
        id="business-type"
        className={containerStyles.businessesSectionMvp}
      >
        <HeadSection
          position="center"
          title="Who will benefit from MVP in product development?"
          description="As a result of the MVP development process, you will have a basic web solution, which can be further developed into a fully-functional product. MVP model works perfectly for any enterprise type, be it a startup looking for potential business opportunities or an established firm launching a new product."
          descriptionLarge
        />
        <ServicesIndustriesCard isColTwo dataContent={MvpBusinesses} />
      </section>

      <section
        id="process"
        className={containerStyles.servicesProcessMvpSection}
      >
        <HeadSection
          position="center"
          title="Codica’s MVP development process"
          description="Thanks to our efficient development process, we build high-quality web products within a short period of time. Creating a minimum viable product usually undergoes the following stages:"
          descriptionLarge
        />
        <ServicesProcess dataContent={MvpProcess} />
        <SubtractBlock isTop />
      </section>

      <section className={containerStyles.benefitsSectionMvp} id="benefits">
        <HeadSection
          position="center"
          title="How your business benefits from MVP development"
          description="MVP (minimum viable product) is the best approach for new product development, allowing you to test a hypothesis fast with no significant investment. MVP means a product with just enough features to satisfy early users. Therefore, you can quickly build your core product and launch it, substantially reducing time-to-market."
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={MvpBenefits} />
      </section>

      <section className={containerStyles.devApproachSectionMvp}>
        <HeadSection
          position="center"
          title="Agile development approach: from MVP to a full-scale product"
          description="Based on our experience, we recommend our startup clients work with agile. Thanks to this methodology, you will see results early, easily make changes as you go, and, most importantly, reduce the price of MVP software development services."
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={MvpApproach} />
      </section>

      <CallToAction
        title="Looking for an MVP development company?"
        subtitle="Tell us about your project."
        classNameForGA="servicesCTA_2"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section className={containerStyles.launchProductSectionMvp}>
        <HeadSection
          position="center"
          title="Launch your product faster with Codica"
          description="Being an MVP software development company, we love technology and innovation. Helping startups bring their ideas to life is our biggest passion."
          titleLarge
          descriptionLarge
        />
        <ServicesSectionBeforeTestimonials
          imageCenter={launchYourProduct}
          content={[
            {
              text: 'One of the most common reasons for startup failures is the lack of market need. Therefore, for each and every startup, regardless of the industry and background, there is a common pain: how to validate your hypothesis within a tight budget and deadlines.',
            },
            {
              text: 'The Codica team understands your concerns. When providing MVP development services, we apply our vast multi-domain expertise. Thus, we skip the learning curve and deliver your minimum viable product within your budget and timeframe expectations.',
            },
            {
              text: 'When building MVPs, we apply the build-measure-learn approach, which is part of the lean startup methodology. With its help, you can eliminate uncertainties, save costs and release your product faster.',
            },
          ]}
        />
        <div className={containerStyles.launchProductSectionMvp__testimonial}>
          <Testimonial
            image={quoteCodicaCEOPhoto}
            name="Dmytro"
            position="CEO & Founder at Codica"
            quote={[
              {
                text: '“MVP model is a great approach to launch a new digital product on the market, and it’s proved by many successful businesses. Such world-famous companies as Airbnb, Facebook,Amazon, Buffer, Uber, Dropbox started with creating a Minimum Viable Product, which included only a core set of functionality.',
              },
              {
                text: 'Speaking about Codica’s experience in MVP software development, we are proud to say that our startup clients raised more than $32 mln of investment. And we would love to help your company be the next to achieve success”',
              },
            ]}
          />
        </div>
      </section>

      <section className={containerStyles.servicesDevExpertiseMvp}>
        <HeadSection
          position="center"
          title="Codica is a reliable MVP development partner"
        />
        <ServicesDevExpertiseCompany dataContent={MvpDevExpertise} />
      </section>

      <section
        id="tech-stack"
        className={containerStyles.technologiesSectionMvp}
      >
        <HeadSection
          position="center"
          title="Minimum viable product development technologies we use"
          description="At Codica, we incorporate a number of modern technologies and tools to provide high-quality MVP development services. Applying our deep expertise in MVP software development and cutting-edge tech stack, we make your business grow and scale."
          titleLarge
          descriptionLarge
        />
        <ServicesTechStackSection />
      </section>

      <section className={containerStyles.successStoriesMvpSection} id="cases">
        <HeadSection
          position="center"
          title="Cases"
          description="Our clients like partnering with us for their leading digital projects."
        />
        <SuccessStoriesSliderSection btnName="View all our works" />
      </section>

      <section className={containerStyles.whyChooseMvpSection}>
        <HeadSection
          position="center"
          title="Why you should choose Codica for MVP development?"
        />
        <CompanyWhyHireBlock dataContent={MvpWhyChoose} />
      </section>

      <LazyHydrate whenVisible>
        <section className={containerStyles.mvpProjLogoSection}>
          <CompanyProjectsLogoSection isCenter />
        </section>
      </LazyHydrate>

      <section className={containerStyles.ourAchievementsMvp}>
        <HeadSection
          position="center"
          title="Our achievements"
          description="Our expertise in web development is confirmed by ratings and research companies."
        />
        <AwardsServicesSection />
      </section>

      <section className={containerStyles.clientsMvpSection}>
        <HeadSection
          position="center"
          title="Our clients say"
          description="Genuine feedback from our clients is precious to us and inspires further progress. We invite you to explore the transformative business experiences of people who entrusted their projects to Codica."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="Richard Gunter"
            location="Israel"
            position="Founder of Impact Personal Training"
            image={clientImpact}
            quote={[
              {
                text: '“Coming from a gym environment, I had no idea how to build, create, and develop an app. Everything I needed to do was explained to me in layman’s terms. I was always up to date with where they were at on the project itself, which I found really helpful.”',
              },
            ]}
            link="fitness-progressive-web-application"
            videoLink="https://www.youtube.com/embed/r1tbx4MEmrY"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Looking for an MVP development company?"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our MVP development experts will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={MvpFAQ} />
      </section>
    </MainLayout>
  )
}

export default MvpDevelopment
