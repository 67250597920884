// extracted by mini-css-extract-plugin
export var benefitsSectionMvp = "B_fp";
export var businessesSectionMvp = "B_fm";
export var clientsMvpSection = "B_fB";
export var devApproachSectionMvp = "B_fq";
export var domainExpertiseMvpSection = "B_fl";
export var launchProductSectionMvp = "B_fr";
export var launchProductSectionMvp__testimonial = "B_fs";
export var mvpProjLogoSection = "B_fy";
export var ourAchievementsMvp = "B_fz";
export var servicesCardBorderIconMvpSection = "B_fk";
export var servicesDevExpertiseMvp = "B_ft";
export var servicesProcessMvpSection = "B_fn";
export var successStoriesMvpSection = "B_fw";
export var technologiesSectionMvp = "B_fv";
export var whyChooseMvpSection = "B_fx";